import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './administradores.css';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const EditarAdministrador = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [administrador, setAdministrador] = useState({
        ...props.administrador,
        email: (props.administrador && props.administrador.email) ? props.administrador.email : '',
        contrasena: '',
        reContrasena: ''
    });
    const [errores, setErrores] = useState({});
    const [hayCambios, setHayCambios] = useState(false);
    const [noCoincidenPass, setNoCoincidenPass] = useState(false);
    const [cambiarContrasena, setCambiarContrasena] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rePasswordVisible, setRePasswordVisible] = useState(false);
    const original = { ...props.administrador };
    const cerrar = () => {
        props.cancelar();
        props.getAdministradores();
    }
    useEffect(() => {
        const { contrasena, reContrasena } = administrador;
        setHayCambios(false);
        if (cambiarContrasena) {
            if (reContrasena && contrasena) {
                if (reContrasena === contrasena) {
                    setNoCoincidenPass(false);
                    setHayCambios(true);
                } else {
                    setNoCoincidenPass(true);
                }
            }
        }
        if ( administrador.usuario && administrador.usuario !== original.usuario ) {
            setHayCambios(true);
        }
        if ( administrador.nombre &&  administrador.nombre !== original.nombre ) {
            setHayCambios(true);
        }
        if ( administrador.apellido &&  administrador.apellido !== original.apellido ) {
            setHayCambios(true);
        }
        if ( administrador.email &&  administrador.email !== original.email ) {
            setHayCambios(true);
        }
    }, [administrador]);
    useEffect(() => {
        setAdministrador({ ...administrador, contrasena: '', reContrasena: '' });
    }, [cambiarContrasena]);
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setAdministrador(usr => ({ ...usr, [name]: value }));
        setErrores(error => ({ ...error, [name]: false }));
    };
    const guardar = () => {
        let todoBien = true;
        let data = {};
        let errores = {};
        const { id, usuario, nombre, apellido, email, contrasena, reContrasena } = administrador;
        //usuario
        if (usuario) {
            if (usuario !== original.usuario) {
                data.usuario = usuario;
            }
            errores.usuario = false;
        } else {
            todoBien = false;
            errores.usuario = true;
        }
        //nombre
        if (nombre) {
            if (nombre !== original.nombre) {
                data.nombre = nombre;
            }
            errores.nombre = false;
        } else {
            //todoBien = false;
            //errores.nombre = true;
        }
        //apellido
        if (apellido) {
            if (apellido !== original.apellido) {
                data.apellido = apellido;
            }
            errores.apellido = false;
        } else {
            todoBien = false;
            errores.apellido = true;
        }
        //email
        if (email) {
            if (email !== original.email) {
                data.email = email;
            }
            errores.email = false;
        } else {
            todoBien = false;
            errores.email = true;
        }
        //contrasena
        if (cambiarContrasena) {
            if (contrasena) {
                data.contrasena = contrasena;
                errores.contrasena = false;
            } else {
                todoBien = false;
                errores.contrasena = true;
            }
            //reContrasena
            if (reContrasena) {
                if (reContrasena === contrasena) {
                    errores.reContrasena = false;
                } else {
                    todoBien = false;
                    errores.reContrasena = true;
                }
            } else {
                todoBien = false;
                errores.reContrasena = true;
            }
        }
        setErrores(errores);
        if (todoBien) {
            data.usuarioId = id;
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('a/usuarios/' + id),
                data: data,
                method: 'PUT'
            };
            Api.requestAxios(options, response => {
                Helper.log('edicionUsuario response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
					if (cambiarContrasena) {
						const newHash = btoa(usuario + ':' + contrasena);
						dispatch(AuthActions.setHash(newHash));
					}
                    cerrar();
                }
            }, error => {
                Helper.log('error edicionUsuario', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al editar usuario', { variant: 'error' });
            });
        }
    };
    return (
        <div>
            <div className="w3-padding-top bs-bbox">
                <form className="w3-margin-bottom w3-padding" id="formCrear">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding">
                            <p>Estás editando los datos de un administrador</p>
                        </div>
                        <div className="w3-col l8 w3-padding bs-bbox">
                            <div className="w3-white bs-bbox w3-padding-large">


                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.usuario}
                                    name="usuario"
                                    type="text"
                                    label="Usuario"
                                    value={administrador.usuario}
                                    onChange={handleChange}
                                    disabled={true} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.nombre}
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    value={administrador.nombre}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.apellido}
                                    name="apellido"
                                    type="text"
                                    label="Apellido"
                                    value={administrador.apellido}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br />

                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.email}
                                    name="email"
                                    type="email"
                                    label="Email"
                                    value={administrador.email}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cambiarContrasena}
                                            onChange={event => setCambiarContrasena(event.target.checked)}
                                            name="cambiarContrasena"
                                            color="primary"
                                        />
                                    }
                                    label="Cambiar contraseña"
                                /><br />



                                {/* <input id="cambiar-contrasena" className="w3-check" type="checkbox"
                                    value={cambiarContrasena}
                                    onChange={event => setCambiarContrasena(event.target.checked)} />
                                <label htmlFor="cambiar-contrasena">Cambiar contraseña</label> */}

                                {cambiarContrasena ? <div>

                                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                        <InputLabel htmlFor="input-password">Contraseña*</InputLabel>
                                        <OutlinedInput
                                            error={errores.contrasena}
                                            id="input-password"
                                            type={passwordVisible ? 'text' : 'password'}
                                            value={administrador.contrasena}
                                            onChange={handleChange}
                                            name="contrasena"
                                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setPasswordVisible((visible) => !visible)}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={85}
                                        />
                                    </FormControl><br /><br /><br />

                                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                        <InputLabel htmlFor="input-repassword">Repetir contraseña*</InputLabel>
                                        <OutlinedInput
                                            error={errores.reContrasena}
                                            id="input-repassword"
                                            type={passwordVisible ? 'text' : 'password'}
                                            value={administrador.reContrasena}
                                            onChange={handleChange}
                                            name="reContrasena"
                                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setRePasswordVisible((visible) => !visible)}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        {rePasswordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={145}
                                        />
                                    </FormControl><br /><br /><br />

                                    {/* <label>Contraseña</label>
                                    <input className={"w3-input w3-border" + (errores.contrasena ? " w3-border-red" : "")}
                                        name="contrasena"
                                        type="password"
                                        placeholder="Contraseña"
                                        value={administrador.contrasena}
                                        onChange={handleChange}
                                        disabled={state.appReducer.actionsDisabled} /><br />

                                    <label>Repetir contraseña</label>
                                    <input className={"w3-input w3-border" + (errores.reContrasena ? " w3-border-red" : "")}
                                        name="reContrasena"
                                        type="password"
                                        placeholder="Repetir Contraseña"
                                        value={administrador.reContrasena}
                                        onChange={handleChange}
                                        disabled={state.appReducer.actionsDisabled} />
                                    {noCoincidenPass && <span className="w3-text-red">No coincide con la contraseña introducida</span>}<br /> */}
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </form>
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cancelar</button>
                    </div>
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                            type="button"
                            disabled={!hayCambios}
                            onClick={() => guardar()}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(EditarAdministrador);