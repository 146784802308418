import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import DetallesPedido from '../../components/ListaPedidos/DetallesPedido';
import { PanelContainer } from 'components/sidenav';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import '../pedidos/pedidos.css';

import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';

import moment from 'moment';
import { ListaPedidos } from 'components/ListaPedidos';
import { OPCIONES_PAGINACION_NUM_RESULTADOS } from 'config/pedidos';

const BootstrapInput = withStyles((theme) => ({
	input: {
		fontSize: 16,
		padding: '10px 26px 10px 12px',
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

const PedidosHistorial = (props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [pedidos, setPedidos] = useState([]);
	const [pedido, setPedido] = useState(null);
	const [panelCrear, setPanelCrear] = useState(false);
	const [panelVer, setPanelVer] = useState(false);
	const [panelEditar, setPanelEditar] = useState(false);
	const [modalEliminar, setModalEliminar] = useState(false);
	//SEARCH
	let searchInput = React.createRef();
	const [requestEnProceso, setRequestEnProceso] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [typing, setTyping] = useState(false);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [fechaDesde, setFechaDesde] = useState(null);
	const [fechaHasta, setFechaHasta] = useState(null);
	const opcionesPaginacionNumResultados = OPCIONES_PAGINACION_NUM_RESULTADOS
	const paginadorInicial = {
		cuantos: opcionesPaginacionNumResultados[0],
		numResultados: 0,
		paginaActual: 0,
		cantidadPaginas: 1,
		maxSize: 10
	}
	const [paginador, setPaginador] = useState(paginadorInicial);
	const filtrosIniciales = {
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			finalizado: true
		}
	}
	const [filtros, setFiltros] = useState(filtrosIniciales);
	const [configPedidos, setConfigPedidos] = useState(null);
	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Productos';
		if (state.authReducer.usuario && state.authReducer.usuario.id) {
			getConfig(filtros);
		} else {
			const { history } = props;
			if (history) history.push('/login');
		}
	}, []);
	useEffect(() => {
		// searchInput.current.focus();
	}, [pedidos]);
	const cambioCuantos = (event) => {
		const cts = event.target.value;
		const fts = { ...filtros, desde: 0, cuantos: cts };
		setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
		buscarResultados(fts);
	}
	const cambioTextoBusqueda = (event) => {
		setSearchText(event.target.value);
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		let texto = (!event.target.value) ? "" : event.target.value;
		let fts = { ...filtros };
		// fts.filtros.nombre[0] = texto;
		setTyping(false);
		setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
	}
	const onChangeFechaDesde = (date) => {
		let fts = { ...filtros };
		if (date) {
			let desde = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
			setFechaDesde(date);
			if (filtros.filtros.fecha) {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, desde: desde } } };
			} else {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: desde } } };
			}
		} else {
			setFechaDesde(null);
			if (filtros.filtros.fecha) {
				if (filtros.filtros.fecha.hasta) {
					fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: filtros.filtros.fecha.hasta } } };
				} else {
					let filtrosFiltros = { ...filtros.filtros };
					delete filtrosFiltros.fecha;
					fts = { ...filtros, filtros: { ...filtrosFiltros } };
				}
			}
		}
		buscarResultados(fts);
	};
	const onChangeFechaHasta = (date) => {
		let fts = { ...filtros };
		if (date) {
			let hasta = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
			setFechaHasta(date);
			if (filtros.filtros.fecha) {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, hasta: hasta } } };
			} else {
				fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: hasta } } };
			}
		} else {
			setFechaHasta(null);
			if (filtros.filtros.fecha) {
				if (filtros.filtros.fecha.desde) {
					fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: filtros.filtros.fecha.desde } } };
				} else {
					let filtrosFiltros = { ...filtros.filtros };
					delete filtrosFiltros.fecha;
					fts = { ...filtros, filtros: { ...filtrosFiltros } };
				}
			}
		}
		buscarResultados(fts);
	};
	const getConfig = () => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('a/config-pedidos'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getConfigPedidos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setConfigPedidos(response.data.datos);
				buscarResultados(filtros);
			}
		}, error => {
			Helper.log('error getConfigPedidos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
		});
	}
	const buscarResultados = (fts) => {
		setFiltros(fts);
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/ver-pedidos'),
			method: 'POST',
			data: fts
		};
		Api.requestAxios(options, response => {
			Helper.log('searchPedidos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPaginador((pgndr) => {
					return {
						...pgndr,
						numResultados: response.data.datos.numResultados,
						cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
					}
				});
				let peds = [];
				for (var i in response.data.datos.pedidos) {
					let ped = {
						...response.data.datos.pedidos[i]
					};
					ped.estados = {
						procesando: ped.procesando ? true : false,
						cancelado: ped.cancelado ? true : false,
						enCamino: ped.enCamino ? true : false,
						entregado: ped.entregado ? true : false,
						finalizado: ped.finalizado == '1' ? true : false,
						listo: ped.listo ? true : false,
						pagado: ped.pagado == '1' ? true : false,
					}
					peds.push(ped);
				}
				setPedidos(peds);
			}
			setRequestEnProceso(false);
		}, error => {
			Helper.log('error searchPedidos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			setRequestEnProceso(false);
		});
	}
	const atras = () => {
		if (filtros.desde > 0) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
				buscarResultados(fts);
			}
		}
	}
	const siguiente = () => {
		if (filtros.desde + paginador.cuantos < paginador.numResultados) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
				buscarResultados(fts);
			}
		}
	}
	const slideSize = (wz) => {
		if (wz.width < 1180) {
			return 100;
		} else {
			return Math.floor((1180 * 100) / wz.width);
		}
	}
	//VER
	const ver = (data) => {
		setPedido({ ...data, abm: 'ver' });
		setPanelVer(true);
	}
	
	//CANCELAR
	const cancelar = () => {
		setPedido(null);
		setPanelVer(false);
		setPanelCrear(false);
		setPanelEditar(false);
		setModalEliminar(false);
	}

	const getPedido = (accion, id) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/pedidos/' + id),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getPedido response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data.datos) {
					switch (accion) {
						case 'ver':
							ver(response.data.datos);
							break;
						default:
							break;
					}
				}

			}
		}, error => {
			Helper.log('error getPedido', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
		});
	}

	const cambiarEstado = (id, estado) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/pedidos/' + id + '/estado'),
			method: 'POST',
			data: estado
		};
		Api.requestAxios(options, response => {
			Helper.log('cambiarEstado response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPaginador(paginadorInicial)
				buscarResultados(filtrosIniciales)
			}
		}, error => {
			Helper.log('error cambiarEstado', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
		});
	}

	const handleAccion = (accion, pedido) => {
		switch (accion) {
			case 'ver':
				getPedido('ver', pedido.id);
				break;
			case 'estado-entregado':
				cambiarEstado(pedido.id, { campo: 'entregado', valor: true });
				break;
			default:
				break;
		}
	}

	return (
		<div>
			<div className="w3-row">
				<div className="w3-rest">
					<h3>Historial de pedidos</h3>
				</div>
			</div>
			<div className="w3-row">
				<div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
					<KeyboardDatePicker
						cancelLabel="Cancelar"
						clearLabel="Limpiar"
						okLabel="Ok"
						clearable
						id="fecha-desde"
						label="Fecha desde"
						format="DD/MM/YYYY"
						value={fechaDesde}
						onChange={onChangeFechaDesde}
						KeyboardButtonProps={{
							'aria-label': 'Cambiar fecha',
						}}
					/>
				</div>
				<div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
					<KeyboardDatePicker
						cancelLabel="Cancelar"
						clearLabel="Limpiar"
						okLabel="Ok"
						clearable
						id="fecha-hasta"
						label="Fecha hasta"
						format="DD/MM/YYYY"
						value={fechaHasta}
						onChange={onChangeFechaHasta}
						KeyboardButtonProps={{
							'aria-label': 'Cambiar fecha',
						}}
					/>
				</div>
				{/* <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                        <InputLabel></InputLabel>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar pedido"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div> */}
			</div>
			{pedidos.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
				<h3 className="w3-text-gray">No hay resultados</h3>
			</div>}
			{pedidos.length > 0 && (
				<>
					<ListaPedidos
						pedidos={pedidos}
						cambiarEstado={cambiarEstado}
						onAccion={handleAccion}
						conAcciones
						conPaginacion
						paraHistorial
					/>
					<div style={{ width: '100%', maxWidth: '500px', float: 'right' }}>
						<div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
						<div className="w3-cell">
						{opcionesPaginacionNumResultados.length > 0 ? <FormControl variant="outlined">
								<Select
									size="small"
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={paginador.cuantos}
									onChange={(e) => cambioCuantos(e)}
									input={<BootstrapInput />}>
									{opcionesPaginacionNumResultados.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
								</Select>
							</FormControl> : null}
						</div>
						<div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
						<div className="w3-cell w3-cell-middle">
							<IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
								<FontAwesomeIcon icon={faChevronLeft} size="sm" />
							</IconButton>
							<IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
								<FontAwesomeIcon icon={faChevronRight} size="sm" />
							</IconButton>
						</div>
					</div>
				</>
			)}

			{pedido && (
				<SlidingPanel type={'right'} isOpen={panelVer} size={100}>
					<PanelContainer close={cancelar} panelLabel="Ver pedido" closeLabel="Pedidos">
						<DetallesPedido onClose={cancelar} pedidoId={pedido.id} />
					</PanelContainer>
				</SlidingPanel>
			)}
		</div >
	);
};
export default withRouter(PedidosHistorial);