import React from 'react'
import { Colors } from 'styles'
import ListItem from '@material-ui/core/ListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

export default function SelectorUbicaciones(props) {
	const { ubicaciones, elegirUbicacion, datosUbicacion } = props

	console.log('ubicaciones', ubicaciones)

	return (
		ubicaciones.length > 0 ? (
			<div className="w3-block">
				{ubicaciones.map((ubicacion) => (
					<ListItem key={ubicacion.id} button onClick={() => elegirUbicacion(ubicacion)} className={ubicacion.id === datosUbicacion?.id ? 'w3-light-gray xbold' : ''}>
						<div className="w3-row">
							<div className="w3-col" style={{ width: '30px', padding: '10px 8px' }}>
								<FontAwesomeIcon icon={faMapMarkerAlt} size="1x" color={ubicacion.id === datosUbicacion?.id ? Colors.THEME : Colors.GRAY_DARK} />
							</div>
							<div className="w3-rest ellipsis">
								<span>{ubicacion?.nombre || 'Sin nombre'}</span><br />
								<span className="w3-text-gray w3-small">{ubicacion?.direccion || 'Dirección no especificada'}</span><br />
							</div>
						</div>
					</ListItem>
				))}
			</div>
		) : (null)
	)
}