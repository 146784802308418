import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronLeft, faChevronRight, faTimes, faExclamationTriangle, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import VerClientes from './ver-cliente';
import CrearCliente from './crear-cliente';
import EditarClientes from './editar-cliente';
import VerPedidos from './ver-pedidos';
import { PanelContainer } from 'components/sidenav';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './clientes.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';

import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import { OPCIONES_PAGINACION_NUM_RESULTADOS } from 'config/clientes';


const OutlinedInputSmall = withStyles({
    root: {
        height: 45,
        backgroundColor: Colors.WHITE
    }
})(OutlinedInput);

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const BotonAccion = withStyles({
    root: {
        margin: '4px'
    }
})(Button);

const BotonAccionInfo = withStyles({
    root: {
        background: `${Colors.INFO}`,
        margin: '4px',
        color: 'white',
        '&:hover': {
            background: `${Colors.INFO}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Clientes = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [panelCrear, setPanelCrear] = useState(false);
    const [panelVer, setPanelVer] = useState(false);
    const [panelEditar, setPanelEditar] = useState(false);
    const [panelVerPedidos, setPanelVerPedidos] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    // const [pathPlanillaClientes, setPathPlanillaClientes] = useState('http://www.222.com.py/Catalogo_Final_2019.pdf');
    const [pathPlanillaClientes, setPathPlanillaClientes] = useState('a/reportes/clientes');
    const windowSize = useWindowSize();
    //SEARCH
    let searchInput = React.createRef();
    const [requestEnProceso, setRequestEnProceso] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [typing, setTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
	const opcionesPaginacionNumResultados = OPCIONES_PAGINACION_NUM_RESULTADOS
	const paginadorIniciales = {
        cuantos: opcionesPaginacionNumResultados[0],
        numResultados: 0,
        paginaActual: 0,
        cantidadPaginas: 1,
    }
    const [paginador, setPaginador] = useState(paginadorIniciales);
	const filtrosIniciales = {
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			nombre: ['']
		}
	}
    const [filtros, setFiltros] = useState(filtrosIniciales);
    const [configClientes, setConfigClientes] = useState(null);

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Clientes';
        if (state.authReducer.usuario && state.authReducer.usuario.id) {
            getConfig(filtros);
        } else {
            const { history } = props;
            if (history) history.push('/login');
        }
    }, []);

    useEffect(() => {
        searchInput.current.focus();
    }, [clientes]);

    const cambioCuantos = (event) => {
        const cts = event.target.value;
        const fts = { ...filtros, desde: 0, cuantos: cts };
        setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
        buscarResultados(fts);
    }

    const cambioTextoBusqueda = (event) => {
        setSearchText(event.target.value);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        let texto = (!event.target.value) ? "" : event.target.value;
        let fts = { ...filtros };
        fts.filtros.nombre[0] = texto;
        setTyping(false);
        setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
    }

    const getConfig = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/config-clientes'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getConfigClientes response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setConfigClientes(response.data.datos);
                buscarResultados(filtros);
            }
        }, error => {
            Helper.log('error getConfigClientes', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
        });
    }

    const buscarResultados = (fts) => {
        setFiltros(fts);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('a/ver-clientes'),
            method: 'POST',
            data: fts
        };
        Api.requestAxios(options, response => {
            Helper.log('searchClientes response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                props.addToast(response.data.msg, 'danger');
            } else {
                setPaginador((pgndr) => {
                    return {
                        ...pgndr,
                        numResultados: response.data.datos.numResultados,
                        cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
                    }
                });
                setClientes(response.data.datos.usuarios);
            }
            setRequestEnProceso(false);
        }, error => {
            Helper.log('error searchClientes', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            setRequestEnProceso(false);
        });
    }

    const atras = () => {
        if (filtros.desde > 0) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
                setPaginador((prev) => ({ ...prev, paginaActual: paginador.paginaActual - 1 }));
                buscarResultados(fts);
            }
        }
    }

    const siguiente = () => {
        if (filtros.desde + paginador.cuantos < paginador.numResultados) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
                setPaginador((prev) => ({ ...prev, paginaActual: paginador.paginaActual + 1 }));
                buscarResultados(fts);
            }
        }
    }

    const slideSize = (wz) => {
        if (wz.width < 1180) {
            return 100;
        } else {
            return Math.floor((1180 * 100) / wz.width);
        }
    }

    //VER
    const ver = (data) => {
        setCliente({ ...data, abm: 'ver' });
        setPanelVer(true);
    }

    //CREAR
    const crear = () => {
        setPanelCrear(true);
    }

    //EDITAR
    const editar = (data) => {
        setCliente({ ...data, abm: 'editar' });
        setPanelEditar(true);
    }

    //ELIMINAR
    const eliminar = (data) => {
        setCliente({ ...data, abm: 'eliminar' });
        setModalEliminar(true);
    }

    const confirmarEliminar = () => {
        var options = {
            url: Api.route('a/clientes/' + cliente.id),
            method: 'DELETE'
        };
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        Api.requestAxios(options, response => {
            Helper.log('deleteCliente response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                enqueueSnackbar(response.data.msg, { variant: 'success' });
                onEliminarClienteSuccess()
            }
        }, error => {
            Helper.log('error deleteCliente', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al eliminar cliente', { variant: 'error' });
        });
    }

    //CANCELAR
    const cancelar = () => {
        setCliente(null);
        setPanelVer(false);
        setPanelCrear(false);
        setPanelEditar(false);
        setModalEliminar(false);
        setPanelVerPedidos(false);
    }

	const onNuevoClienteSuccess = () => {
		setPanelCrear(false)
		setPaginador(paginadorIniciales)
		buscarResultados(filtrosIniciales)
	}

	const onEditarClienteSuccess = () => {
		setPanelEditar(false)
		setPaginador(paginadorIniciales)
		buscarResultados(filtrosIniciales)
	}

	const onEliminarClienteSuccess = () => {
		setModalEliminar(false)
		setPaginador(paginadorIniciales)
		buscarResultados(filtrosIniciales)
	}

    //pedidos
    const verPedidos = (data) => {
        setCliente({ ...data, abm: 'ver-pedidos' });
        setPanelVerPedidos(true);
    }
    const clickOpcion = (opcion, data, index) => {
        let clnts = [...clientes];
        clnts[index].anchorEl = null;
        setClientes(clnts);
        switch (opcion) {
            case 'ver':
                ver(data);
                break;
            case 'editar':
                editar(data);
                break;
            case 'eliminar':
                eliminar(data);
                break;
            case 'pedidos':
                verPedidos(data);
                break;
            default:
                break;
        }
    }
    const onClickOpciones = (event, index) => {
        let clnts = [...clientes];
        clnts[index].anchorEl = event.currentTarget;
        setClientes(clnts);
    }
    return (
        <div>
            <div className="w3-row">
                <div className="w3-col w3-right w3-right-align" style={{ width: '300px' }}>
                    <Button onClick={() => crear()} color="primary" variant="contained">Agregar cliente</Button>
                </div>
                <div className="w3-rest">
                    <h3>Clientes</h3>
                </div>
            </div>
            {/* <div className="w3-block">
                <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                    <InputLabel></InputLabel>
                    <OutlinedInputSmall
                        ref={searchInput}
                        placeholder="Buscar cliente"
                        id="input-search"
                        type="text"
                        fullWidth={true}
                        value={searchText}
                        onChange={(e) => cambioTextoBusqueda(e)}
                        disabled={(state.appReducer.actionsDisabled) ? true : false}
                        startAdornment={
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div> */}
            <div className="w3-row">
                <div className="w3-col l6 m6 s6">
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                        <InputLabel></InputLabel>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar cliente"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div className="w3-col l6 m6 s6">
                    { pathPlanillaClientes ? <a target="_blank" className="w3-btn w3-green w3-round w3-right" href={ CONFIG.URL_BASE + pathPlanillaClientes }>
                        <FontAwesomeIcon icon={faFileExcel} size="1x" color={'#fff'} style={{marginRight: '5px'}}/>
                        <span>Ver planilla</span>
                    </a> : null }
                </div>
            </div>
            {clientes.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
                <h3 className="w3-text-gray">No hay resultados</h3>
            </div>}
            {clientes.length > 0 ? <div className="w3-block w3-margin-top">
                <table className="w3-table w3-border w3-bordered tabla w3-white">
                    <thead className="w3-border">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Num. pedidos</th>
                            <th>Monto total</th>
                            <th>Fecha registro</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="w3-border">
                        {clientes.map((clie, index) => <tr key={clie.id} className={clie.anchorEl ? 'w3-light-gray' : ''}>
                            <td>{clie.usuario}</td>
                            <td>{(clie.nombre) && <span>{clie.nombre}&nbsp;</span>}{(clie.apellido) && <span>{clie.apellido}</span>}</td>
                            <td>{clie.telefono ? clie.telefono : '--'}</td>
                            <td>{clie.numPedidos || '-'}</td>
                            <td>{Helper.numberUi(clie.montoTotal)}</td>
                            <td>{clie.fechaRegistro}</td>
                            <td style={{ width: '120px' }}>
                                <BotonAccionInfo variant="contained" color="primary" aria-controls={'menu-opciones-' + clie.id} aria-haspopup="true" onClick={(e) => onClickOpciones(e, index)}>
                                    Opciones
                                </BotonAccionInfo>
                                <Menu
                                    id={'menu-opciones-' + clie.id}
                                    anchorEl={clie.anchorEl}
                                    keepMounted
                                    open={Boolean(clie.anchorEl)}
                                    onClose={() => clickOpcion('cerrar', clie, index)}>
                                    <MenuItem onClick={() => clickOpcion('ver', clie, index)}>Ver</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('editar', clie, index)}>Editar</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('eliminar', clie, index)} disabled={clie.numPedidos > 0}>Eliminar</MenuItem>
                                    <MenuItem onClick={() => clickOpcion('pedidos', clie, index)}>Pedidos</MenuItem>
                                </Menu>
                            </td>
                            {/* <td style={{ width: '260px' }}>
                                <BotonAccionInfo onClick={() => ver(clie)} variant="contained" size="small">Ver</BotonAccionInfo>
                                <BotonAccion onClick={() => editar(clie)} color="primary" variant="contained" size="small">Editar</BotonAccion>
                                <BotonAccion onClick={() => eliminar(clie)} color="secondary" variant="contained" size="small">Eliminar</BotonAccion>
                                <BotonAccionSuccess onClick={() => pedidos(clie)} color="secondary" variant="contained" size="small">Pedidos</BotonAccionSuccess>
                            </td> */}
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7" className="w3-small">
                                <div style={{ width: '100%', maxWidth: '300px', float: 'right' }}>
                                    <div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
                                    <div className="w3-cell">
										{opcionesPaginacionNumResultados.length > 0 ? <FormControl variant="outlined">
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={paginador.cuantos}
                                                onChange={(e) => cambioCuantos(e)}
                                                input={<BootstrapInput />}>
                                                {opcionesPaginacionNumResultados.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                                            </Select>
                                        </FormControl> : null}
                                    </div>
                                    <div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
                                    <div className="w3-cell w3-cell-middle">
                                        <IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
                                            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                                        </IconButton>
                                        <IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
                                            <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                        </IconButton>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> : null}


            {/* {!state.appReducer.loading && <ReactPaginate pageCount={2}
                pageRangeDisplayed={5}
                marginPagesDisplayed={5}
                pageClassName="w3-btn w3-border paginator-li"
                previousLabel="Anterior"
                nextLabel="Siguiente"
                activeClassName="w3-blue"
                previousClassName="w3-btn w3-border paginator-li"
                nextClassName="w3-btn w3-border paginator-li" />} */}

            <SlidingPanel type={'right'} isOpen={panelVer} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Ver cliente" closeLabel="Clientes">
                    <VerClientes cancelar={cancelar} cliente={cliente} configClientes={configClientes} />
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelCrear} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Nuevo cliente" closeLabel="Productos">
                    <CrearCliente
						onCancelar={cancelar}
						onSuccess={onNuevoClienteSuccess}
						configClientes={configClientes}
					/>
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelEditar} size={slideSize(windowSize)}>
                <PanelContainer close={cancelar} panelLabel="Edición cliente" closeLabel="Clientes">
                    <EditarClientes
						onCancelar={cancelar}
						onSuccess={onEditarClienteSuccess}
						cliente={cliente}
						configClientes={configClientes}
					/>
                </PanelContainer>
            </SlidingPanel>

            <SlidingPanel type={'right'} isOpen={panelVerPedidos} size={100}>
                <PanelContainer close={cancelar} panelLabel="Ver pedidos" closeLabel="Clientes">
                    <VerPedidos cancelar={cancelar} cliente={cliente} />
                </PanelContainer>
            </SlidingPanel>

            {(modalEliminar && cliente) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cancelar()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{ margin: 0 }}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <p className="w3-large w3-margin w3-center">Estás a punto de eliminar el cliente {cliente.nombre}. Ésta acción no se puede deshacer.</p>
                        <p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
                        <div className="w3-row w3-white">
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                                    type="button"
                                    onClick={() => cancelar()}>No</button>
                            </div>
                            <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => confirmarEliminar()}>Si</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(Clientes);