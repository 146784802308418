import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { Helper } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Switch } from 'components/switch'
import { OPCIONES_ESTADO_PEDIDO_HISTORIAL, OPCIONES_ESTADO_PEDIDO_PENDIENTE, getEstadoReglaDisabled, isConEnvio } from 'config/pedidos'
import { withStyles } from '@material-ui/core/styles'
import { Colors } from 'styles'
import 'app/pedidos/pedidos.css'

const BotonAccionInfo = withStyles({
	root: {
		background: `${Colors.INFO}`,
		margin: '4px',
		color: 'white',
		'&:hover': {
			background: `${Colors.INFO}`,
		}
	},
	label: {
		textTransform: 'uppercase',
	}
})(Button)

const ListaPedidos = (props) => {
	const { pedidos, cambiarEstado, onAccion, conAcciones, paraHistorial = false, soloVer = false } = props

	const opcionesEstadoPedido = paraHistorial ? OPCIONES_ESTADO_PEDIDO_HISTORIAL : OPCIONES_ESTADO_PEDIDO_PENDIENTE

	const [anchorEl, setAnchorEl] = useState(null)
	const [pedidoIdAct, setPedidoIdAct] = useState(null)

	const pedido = pedidos.find(pedido => pedido.id === pedidoIdAct)

	const handleAccion = (cual, p = null) => {
		onCloseOpciones()
		onAccion(cual, p === null ? pedido : p)
	}

	const onClickOpciones = (event, pedidoId) => {
		setAnchorEl(event.currentTarget)
		setPedidoIdAct(pedidoId)
	}

	const onCloseOpciones = () => {
		setAnchorEl(null)
		setPedidoIdAct(null)
	}

	const onClickVer = (pedidoId) => {
		const p = pedidos.find(pedido => pedido.id === pedidoId)
		handleAccion('ver', p)
	}

	const getEstado = (ped) => {
		let status = {
			estado: 'Pendiente',
			color: '#666D92'
		}

		if (ped.recibido) {
			status = {
				estado: 'Pendiente',
				color: '#666D92'
			}
		}
		if (ped.procesando) {
			status = {
				estado: 'Procesando',
				color: '#2067FA'
			}
		}
		if (ped.listo) {
			status = {
				estado: 'Listo',
				color: '#00C58D'
			}
		}
		if (ped.enCamino) {
			status = {
				estado: 'En camino',
				color: '#43eb3d'
			}
		}
		if (ped.entregado) {
			status = {
				estado: 'Entregado',
				color: '#55c433'
			}
		}
		if (ped.cancelado) {
			status = {
				estado: 'Cancelado',
				color: '#FC5C63'
			}
		}
		return status;
	}

	const toggleEstado = (campo) => {
		setAnchorEl(null)
		setPedidoIdAct(null)
		const valorAnterior = pedido.estados[campo]

		cambiarEstado(pedido.id, { campo, valor: !valorAnterior })
	}

	const getNombreCompleto = (user) => {
		let nombre = '--';
		if (user) {
			if (user.nombre) {
				nombre = user.nombre;
			}
			if (user.nombre && user.apellido) {
				nombre = user.nombre + ' ' + user.apellido;
			}
		}
		return nombre;
	}

	return (
		<>
			{anchorEl && (
				<Menu
					id={'menu-opciones'}
					key={pedido.id}
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={onCloseOpciones}>
					<MenuItem onClick={() => handleAccion('ver')}>Ver</MenuItem>
					{/*<MenuItem onClick={() => handleAccion('editar')}>Editar</MenuItem>*/}
					{!paraHistorial && <MenuItem onClick={() => handleAccion('cancelar')}>Cancelar</MenuItem>}
					{!paraHistorial && pedido.via === 'admin' && <MenuItem onClick={() => handleAccion('eliminar')}>Eliminar</MenuItem>}
					<hr />
					{opcionesEstadoPedido.map((estado, i) => {
						// Si es para el historial y está cancelado, reemplaza entregado por cancelado
						if (paraHistorial && pedido.cancelado && estado.key === 'entregado') {
							return (
								<MenuItem
									key={`estado-${i}`}
									onClick={(e) => toggleEstado('cancelado')}
								>
									<Switch
										checked={pedido.estados.cancelado}
										onChange={() => { }}
										name={`cancelado-${i}`}
									/>
									<span>&nbsp;&nbsp;No cancelado</span>
								</MenuItem>
							)
						} else {
							return (
								<MenuItem
									key={`estado-${i}`}
									onClick={(e) => toggleEstado(estado.key)}
									disabled={getEstadoReglaDisabled(estado.key, pedido)}
								>
									<Switch
										checked={pedido.estados[estado.key]}
										name={`${estado.key}-${i}`}
										onChange={() => { }}
									/>
									<span>&nbsp;&nbsp;{estado.label}</span>
								</MenuItem>
							)
						}
					})}
					{!paraHistorial && (
						<MenuItem
							onClick={(e) => toggleEstado('pagado')}
						>
							<Switch
								checked={pedido.estados.pagado}
								onChange={() => { }}
								name={`pagado-${pedido.id}`}
							/>
							<span>&nbsp;&nbsp;Pagado</span>
						</MenuItem>
					)}
				</Menu>
			)}
			<table className="tabla-pedidos w3-table w3-border w3-bordered w3-white">
				<thead className="w3-border">
					<tr>
						<th>Id</th>
						<th>Cliente</th>
						<th>Fecha</th>
						<th>Entrega</th>
						<th>Monto total</th>
						<th style={{ minWidth: 130 }}></th>
						{(conAcciones || soloVer) && <th></th>}
					</tr>
				</thead>
				<tbody className="w3-border">
					{pedidos.map((ped) => {
						const conEnvio = isConEnvio(ped.tipo)
						const entregaTexto = conEnvio ? 'Con envío' : 'Retiro'
						const estado = getEstado(ped)
						const isCancelado = estado.estado === 'Cancelado'

						return (
							<tr key={ped.id} className={`via-${ped.via} ${ped.id === pedidoIdAct ? 'w3-light-gray' : ''} ${isCancelado ? 'pedido-cancelado' : ''}`}>
								<td>{ped.id}</td>
								<td>{getNombreCompleto(ped.usuario)}</td>
								<td>{Helper.datetimeToUiFormat(ped.recibido)}</td>
								<td><span className={`tag ${ped.tipo}`}>{entregaTexto}</span></td>
								<td>{Helper.numberUi(ped.montoTotal)} Gs. {ped.estados.pagado && <FontAwesomeIcon icon={faCheck} color={Colors.SUCCESS} />}</td>
								<td style={{ width: '120px' }}>
									<FontAwesomeIcon icon={faCircle} color={estado.color} />
									&nbsp;<b>{estado.estado}</b>
								</td>
								{conAcciones && (
									<td style={{ width: '120px' }}>
										<BotonAccionInfo
											variant="contained"
											color="primary"
											aria-controls={'menu-opciones-' + ped.id}
											aria-haspopup="true"
											onClick={(e) => onClickOpciones(e, ped.id)}
										>
											Opciones
										</BotonAccionInfo>
									</td>
								)}
								{soloVer && (
									<td style={{ width: '120px' }}>
										<BotonAccionInfo
											variant="contained"
											size="small"
											onClick={() => onClickVer(ped.id)}
										>
											Ver
										</BotonAccionInfo>
									</td>
								)}
							</tr>
						)
					})}
				</tbody>
			</table>
		</>
	)
}

export default ListaPedidos