import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import Geocode from 'react-geocode'
import CONFIG from 'config';
import getPoligonoStyles from './polygonStyles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import { useSnackbar } from 'notistack'
import Marker from './Marker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { defaultCenter, defaultZoom, defaultGetUbicacionOptions } from './config'

Geocode.setApiKey(CONFIG.GOOGLE_MAP_KEY)
Geocode.setLanguage('es')

const GoogleMap = (props) => {
	const {
		mapHeight,
		mapWidth,
		areas = [],
		ubicacion,
		setUbicacion,
		disabled,
		titulo = 'Definir ubicación',
		datosUbicacionLabel = 'Favor especificar la dirección exacta',
		miUbicacionLabel = 'Usar mi ubicación',
		fueraDeAreaError = 'Ubicación seleccionada fuera del área de cobertura',
		noSeleccionadaMsg = 'Favor seleccionar ubicación'
	} = props

	const { enqueueSnackbar } = useSnackbar()
	const [mapas, setMapas] = useState(null)
	const [mapa, setMapa] = useState(null)

	useEffect(() => {
		if (ubicacion?.id) {
			const bounds = new mapas.LatLngBounds()
			bounds.extend(new mapas.LatLng(ubicacion.lat, ubicacion.lng - 0.005562184202671051,))
			bounds.extend(new mapas.LatLng(ubicacion.lat, ubicacion.lng + 0.005562184202671051,))
			bounds.extend(new mapas.LatLng(ubicacion.lat, ubicacion.lng,))
			mapa.fitBounds(bounds)
		}
	}, [ubicacion?.id])

	useEffect(() => {
		if (ubicacion) {
			verificarCobertura(ubicacion.lat, ubicacion.lng)
		}
	}, [ubicacion?.lat, ubicacion?.lng])

	const poligonos = []
	areas.forEach((area, i) => {
		poligonos.push({
			paths: area.poligono.map((punto) => ({ lat: punto.lat, lng: punto.lon })),
			...getPoligonoStyles(area.color)
		})
	})

	const datosVacios = {
		direccion: '',
		barrio: '',
		ciudad: '',
		departamento: '',
		pais: ''
	}

	const handleChangeDatosUbicacion = (value, cual) => {
		setUbicacion(prev => ({
			...prev,
			[cual]: value
		}))
	}

	const getGeoData = (json) => {
		let datosGeo = {
			direccion: null,
			barrio: null,
			ciudad: null,
			departamento: null,
			pais: null
		}

		if (json && json.results && json.results.length > 0) {
			for (var i in json.results) {
				if (json.results[i].types[0] === "route") {
					datosGeo.calle = json.results[i]['formatted_address'].split(",")[0]
				}
				if (json.results[i].types[0] === "street_address") {
					datosGeo.direccion = json.results[i]['formatted_address'].split(",")[0]
				}
				if (json.results[i].types[0] === "neighborhood") {
					datosGeo.barrio = json.results[i]['formatted_address'].split(",")[0]
				}
				if (json.results[i].types[0] === "locality") {
					datosGeo.ciudad = json.results[i]['formatted_address'].split(",")[0]
				}
				if (json.results[i].types[0] === "administrative_area_level_1") {
					datosGeo.departamento = json.results[i]['formatted_address'].split(",")[0]
				}
				if (json.results[i].types[0] === "country") {
					datosGeo.pais = json.results[i]['formatted_address'].split(",")[0]
				}
			}

			if (!datosGeo.direccion) {
				if (datosGeo.calle) {
					datosGeo.direccion = datosGeo.calle;
				} else if (datosGeo.ciudad) {
					datosGeo.direccion = datosGeo.ciudad;
				}
			}
		}

		return datosGeo
	}

	function usarUbicacionSuccess(pos) {
		setUbicacion({
			lat: pos.coords.latitude,
			lng: pos.coords.longitude,
			...datosVacios
		})

		const bounds = new mapas.LatLngBounds()
		bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude - 0.005562184202671051,))
		bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude + 0.005562184202671051,))
		bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude,))
		mapa.fitBounds(bounds)

	}

	function usarUbicacionError(err) {
		setUbicacion(null);
		enqueueSnackbar('No se pudo obtener tu ubicación', { variant: 'error' })
	}

	const usarMiUbicacion = () => {
		navigator.geolocation.getCurrentPosition(usarUbicacionSuccess, usarUbicacionError, defaultGetUbicacionOptions)
	}

	const onMapClick = ({ x, y, lat, lng, event }) => {
		if (!disabled) {
			setUbicacion({
				lat,
				lng,
				...datosVacios
			})
		}
	}

	const verificarCobertura = (lat, lng) => {
		const punto = new mapas.LatLng(lat, lng)

		let indicePoligono = -1
		poligonos.forEach((poligono, i) => {
			const poligonoGoogle = new mapas.Polygon(poligono)
			const estaDentro = mapas.geometry.poly.containsLocation(punto, poligonoGoogle)

			if (estaDentro) {
				indicePoligono = i
			}
		})

		// Mostramos el resultado al usuario
		if (indicePoligono === -1) {
			setUbicacion({
				...ubicacion,
				indicePoligono: -1
			})
		} else {
			Geocode.fromLatLng(lat, lng).then(
				response => {
					const geoData = getGeoData(response)
					const nuevaUbicacion = {
						lat: lat,
						lng: lng,
						direccion: geoData.direccion,
						barrio: geoData.barrio,
						ciudad: geoData.ciudad,
						departamento: geoData.departamento,
						pais: geoData.pais,
						indicePoligono: indicePoligono
					}
					setUbicacion(prev => ({
						...prev,
						...nuevaUbicacion
					}))
				},
				error => {
					console.error(error)
				}
			)
		}
	}

	const handleApiLoaded = (map, maps) => {
		setMapas(maps)
		setMapa(map)
		poligonos.map((poly, i) => {
			const poligono = new maps.Polygon(poly)
			poligono.setMap(map)
		})
	}

	return (
		<div>
			<div>
				<h4 style={{ margin: 0 }}>{titulo}</h4>
			</div>
			<div className="w3-right-align">
				<Button onClick={() => usarMiUbicacion()} variant="contained" style={{ marginTop: 10, marginBottom: 0, marginLeft: 1 }} disabled={disabled}>
					<FontAwesomeIcon
						icon={faCrosshairs}
						className="w3-margin-right"
					/>{miUbicacionLabel}
				</Button>
			</div>
			<div style={{ height: mapHeight, width: mapWidth }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
					onClick={onMapClick}
					defaultCenter={defaultCenter}
					defaultZoom={defaultZoom}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
				>
					{ubicacion ? <Marker lat={ubicacion.lat} lng={ubicacion.lng} /> : null}
				</GoogleMapReact>
			</div>
			{ubicacion && ubicacion.indicePoligono !== -1 ? <div className="w3-block">
				<div style={{ marginTop: 15, marginBottom: 10 }}><strong>{datosUbicacionLabel}</strong></div>
				<TextField required fullWidth margin="dense" variant="outlined"
					name="direccion"
					type="text"
					label="Dirección"
					value={ubicacion.direccion}
					onChange={(e) => handleChangeDatosUbicacion(e.target.value, 'direccion')}
					disabled={disabled}
				/>
				<br />
				<TextField required fullWidth margin="normal" variant="outlined"
					name="barrio"
					type="text"
					label="Barrio"
					value={ubicacion.barrio}
					onChange={(e) => handleChangeDatosUbicacion(e.target.value, 'barrio')}
					disabled={disabled}
				/>
				<br />
				<TextField required fullWidth margin="normal" variant="outlined"
					name="ciudad"
					type="text"
					label="Ciudad"
					value={ubicacion.ciudad}
					onChange={(e) => handleChangeDatosUbicacion(e.target.value, 'ciudad')}
					disabled={disabled}
				/>
				<br />
			</div> : (
				<>
					{!ubicacion && (
						<Alert severity="warning" sx={{ mt: 2 }}>
							{noSeleccionadaMsg}
						</Alert>
					)}

					{ubicacion && ubicacion.indicePoligono === -1 && (
						<Alert severity="error" sx={{ mt: 2 }}>
							{fueraDeAreaError}
						</Alert>
					)}
				</>
			)}
		</div>
	)
}

export default GoogleMap