export const defaultCenter = {
	lat: -25.276750711185308,
	lng: -57.63522231951356
}

export const defaultZoom = 10

export const defaultGetUbicacionOptions = {
	enableHighAccuracy: true,
	timeout: 5000,
	maximumAge: 0
}