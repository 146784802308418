//css
import './app.css';
//js
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, withRouter, Switch } from "react-router-dom";
import { AppStorage } from 'utils';
import CONFIG from "config";

import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "moment/locale/es";
import { LogoBlanco } from 'assets/logos';
/* componentes */
import { Toasts, Loading } from 'components/w3';
import { Menu } from 'app/menu';
/* páginas */
import { Login } from 'app/autenticacion';
import { Perfil } from 'app/perfil';
import { Dashboard } from 'app/dashboard';
import { Administradores } from 'app/administradores';
import { Productos } from 'app/productos';
import { Jerarquias } from 'app/jerarquias';
import { Clientes } from 'app/clientes';
import { Pedidos } from 'app/pedidos';
import { PedidosHistorial } from 'app/pedidos-historial';
import { Secciones } from 'app/secciones';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import VerPedido from './pedidos/ver-pedido';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
const useStylesNotistack = makeStyles((theme) => ({
    containerAnchorOriginTopRight: {
        zIndex: 16000
    }
}));

const App = props => {
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const classes = useStyles();
    const classesNotistack = useStylesNotistack();
    const { location } = props;
    const { pathname } = location;
    useEffect(() => {
    }, [])
    function logout() {
        dispatch(AuthActions.setHash(null));
        AppStorage.clearAll();
        const { history } = props;
        if (history) history.push('/login');
    }
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }
    return (
        <SnackbarProvider
            classes={classesNotistack}
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackRef}
            action={(key) => (
                <IconButton aria-label="cerrar" onClick={onClickDismiss(key)}>
                    <FontAwesomeIcon icon={faTimes} color={'#ffffff'} size="xs" />
                </IconButton>
            )}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <div id="main-content">
                    {state.appReducer.loading ? <Loading fondo='rgba(0,0,0,0.2)' /> : ''}
                    <Switch>
                        <Route exact path="/login" render={(props) => <Login {...props} />} />
                        {state.authReducer.hash ? <Route path="/panel">
                            <div className={classes.root}>
                                <CssBaseline />
                                <AppBar position="fixed">
                                    <Toolbar>
                                        <div className="w3-row">
                                            <div className="w3-col" style={{ width: '40px' }}>
                                                <img src={LogoBlanco} alt="Logo" style={{ width: '200px' }} />
                                            </div>
											{/*
                                            <div className="w3-rest">
                                                <Typography variant="h6" noWrap>{CONFIG.APP_NAME}</Typography>
                                            </div>
											*/}
                                        </div>
                                    </Toolbar>
                                </AppBar>
                                <Menu logout={logout} />
                                <main className={classes.content}>
                                    <div className={classes.toolbar} />
                                    <Switch>
                                        <Route exact path="/panel/dashboard" render={(props) => <Dashboard {...props} />} />
                                        <Route exact path="/panel/administradores" render={(props) => <Administradores {...props} />} />
                                        <Route exact path="/panel/categorias" render={(props) => <Jerarquias {...props} />} />
                                        <Route exact path="/panel/productos" render={(props) => <Productos {...props} />} />
                                        <Route exact path="/panel/perfil" render={(props) => <Perfil {...props} />} />
                                        <Route exact path="/panel/clientes" render={(props) => <Clientes {...props} />} />
                                        <Route exact path="/panel/pedidos" render={(props) => <Pedidos {...props} />} />
										<Route exact path="/panel/pedidos/:pedidoId" render={(props) => <VerPedido {...props} />} />
                                        <Route exact path="/panel/pedidos-historial" render={(props) => <PedidosHistorial {...props} />} />
                                        <Route exact path="/panel/secciones/:seccionId" render={(props) => <Secciones {...props} />} />
                                    </Switch>
                                </main>
                            </div>
                        </Route> : null}
                        <Route exact path="/">
                            {state.authReducer.hash ? <Redirect to="/panel/dashboard" /> : <Redirect to="/login" />}
                        </Route>
                    </Switch>
                </div>
            </MuiPickersUtilsProvider>
        </SnackbarProvider>
    );
}
export default withRouter(App);