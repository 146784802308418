import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import CrearPedido from './crear-pedido';
import EditarPedido from './editar-pedido';
import { PanelContainer } from 'components/sidenav';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './pedidos.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase'


// import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';

import { OPCIONES_MOTIVOS_CANCELACION, OPCIONES_PAGINACION_NUM_RESULTADOS, OPCIONES_TIPOS_PEDIDO_PARA_FILTRO } from 'config/pedidos';
import { DetallesPedido, ListaPedidos } from 'components/ListaPedidos';

// const OutlinedInputSmall = withStyles({
//     root: {
//         height: 45,
//         backgroundColor: Colors.WHITE
//     }
// })(OutlinedInput);

const BootstrapInput = withStyles((theme) => ({
	input: {
		fontSize: 16,
		padding: '10px 26px 10px 12px',
	},
}))(InputBase);

const RadioTheme = withStyles({
	root: {
		color: Colors.THEME,
		'&checked': {
			color: Colors.THEME_D1,
		},
	},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

const useSelectStyles = makeStyles((theme) => ({
	formControl: {
		margin: '0px 2px',
		minWidth: 200,
	},
	selectEmpty: {
		marginTop: '0px',
	},
}));

const Pedidos = (props) => {
	const classes = useStyles();
	const selectClass = useSelectStyles();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [pedidos, setPedidos] = useState([]);
	const [pedido, setPedido] = useState(null);
	const [panelCrear, setPanelCrear] = useState(false);
	const [panelVer, setPanelVer] = useState(false);
	const [panelEditar, setPanelEditar] = useState(false);
	const [modalEliminar, setModalEliminar] = useState(false);
	const [modalCancelarPedido, setModalCancelarPedido] = useState(false);
	const [motivoCancelacion, setMotivoCancelacion] = useState('');
	const [otroMotivoCancelacion, setOtroMotivoCancelacion] = useState('');
	// const windowSize = useWindowSize();
	//SEARCH
	// let searchInput = React.createRef();
	const [requestEnProceso, setRequestEnProceso] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [typing, setTyping] = useState(false);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [tipoSelected, setTipoSelected] = useState('todos');
	const opcionesPaginacionNumResultados = OPCIONES_PAGINACION_NUM_RESULTADOS
	const paginadorInicial = {
		cuantos: opcionesPaginacionNumResultados[0],
		numResultados: 0,
		paginaActual: 0,
		cantidadPaginas: 1,
		maxSize: 10
	}
	const [paginador, setPaginador] = useState(paginadorInicial);
	const filtrosIniciales = {
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			finalizado: false
		}
	}
	const [filtros, setFiltros] = useState(filtrosIniciales);

	const [configPedidos, setConfigPedidos] = useState(null);

	const opcionesMotivosCancelacion = OPCIONES_MOTIVOS_CANCELACION
	const opcionesFiltroTipo = OPCIONES_TIPOS_PEDIDO_PARA_FILTRO

	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Productos';
		if (state.authReducer.usuario && state.authReducer.usuario.id) {
			getConfig(filtros);
		} else {
			const { history } = props;
			if (history) history.push('/login');
		}
	}, []);

	useEffect(() => {
		// searchInput.current.focus();
	}, [pedidos]);

	// const cambiarPagina = (data) => {
	//     console.log('cambiarPagina', data);
	//     const paginaActual = data.selected;
	//     if (!requestEnProceso) {
	//         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
	//         setFiltros(fts);
	//         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
	//         buscarResultados(fts);
	//     }
	// }
	const cambioCuantos = (event) => {
		const cts = event.target.value;
		const fts = { ...filtros, desde: 0, cuantos: cts };
		setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
		buscarResultados(fts);
	}

	const changeSelectTipo = (tipo) => {
		setTipoSelected(tipo);
		let fts = { ...filtros };
		if (tipo === 'todos') {
			if (fts.filtros && fts.filtros.tipo !== undefined) {
				delete fts.filtros.tipo;
			}
		} else {
			fts.filtros.tipo = tipo;
		}
		setFiltros(fts);
		buscarResultados(fts);
	};
	// const cambioTextoBusqueda = (event) => {
	//     setSearchText(event.target.value);
	//     if (typingTimeout) {
	//         clearTimeout(typingTimeout);
	//     }
	//     let texto = (!event.target.value) ? "" : event.target.value;
	//     let fts = { ...filtros };
	//     // fts.filtros.nombre[0] = texto;
	//     setTyping(false);
	//     setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
	// }

	const getConfig = () => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('a/config-pedidos'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getConfigPedidos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setConfigPedidos(response.data.datos);
				buscarResultados(filtros);
			}
		}, error => {
			Helper.log('error getConfigPedidos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
		});
	}

	const buscarResultados = (fts) => {
		setFiltros(fts);
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/ver-pedidos'),
			method: 'POST',
			data: fts
		};
		Api.requestAxios(options, response => {
			Helper.log('searchPedidos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPaginador((pgndr) => {
					return {
						...pgndr,
						numResultados: response.data.datos.numResultados,
						cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
					}
				});
				let peds = [];
				for (var i in response.data.datos.pedidos) {
					let ped = {
						...response.data.datos.pedidos[i]
					};
					ped.estados = {
						procesando: ped.procesando ? true : false,
						cancelado: ped.cancelado ? true : false,
						enCamino: ped.enCamino ? true : false,
						entregado: ped.entregado ? true : false,
						finalizado: ped.finalizado == '1' ? true : false,
						listo: ped.listo ? true : false,
						pagado: ped.pagado == '1' ? true : false,
					}
					peds.push(ped);
				}
				setPedidos(peds);
			}
			setRequestEnProceso(false);
		}, error => {
			Helper.log('error searchPedidos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			setRequestEnProceso(false);
		});
	}

	const atras = () => {
		if (filtros.desde > 0) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
				buscarResultados(fts);
			}
		}
	}

	const siguiente = () => {
		if (filtros.desde + paginador.cuantos < paginador.numResultados) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
				buscarResultados(fts);
			}
		}
	}

	const slideSize = (wz) => {
		if (wz.width < 1180) {
			return 100;
		} else {
			return Math.floor((1180 * 100) / wz.width);
		}
	}
	//VER
	const ver = (data) => {
		setPedido({ ...data, abm: 'ver' });
		setPanelVer(true);
	}

	//CREAR
	const crear = () => {
		setPanelCrear(true);
	}

	//EDITAR
	const editar = (data) => {
		setPedido({ ...data, abm: 'editar' });
		setPanelEditar(true);
	}

	//ELIMINAR
	const eliminar = (data) => {
		setPedido({ ...data, abm: 'eliminar' });
		setModalEliminar(true);
	}

	const confirmarEliminar = () => {
		var options = {
			url: Api.route('a/pedidos/' + pedido.id),
			method: 'DELETE'
		};
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		Api.requestAxios(options, response => {
			Helper.log('deletePedido response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				enqueueSnackbar(response.data.msg, { variant: 'success' });
				setPaginador(paginadorInicial)
				buscarResultados(filtrosIniciales);
				cancelar()
			}
		}, error => {
			Helper.log('error deletePedido', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al eliminar administrador', { variant: 'error' });
		});
	}

	//CANCELAR PEDIDO
	const cancelarPedido = (data) => {
		setMotivoCancelacion('');
		setPedido({ ...data, abm: 'cancelar' });
		setModalCancelarPedido(true);
	};

	const onChangeMotivo = (event) => {
		setMotivoCancelacion(event.target.value);
		if (event.target.value === 'otro') {
			setOtroMotivoCancelacion('');
		}
	};

	const onChangeOtroMotivo = (event) => {
		setOtroMotivoCancelacion(event.target.value);
	};

	//CANCELAR CANCELAR PEDIDO
	const cancelarCancelarPedido = (data) => {
		setMotivoCancelacion('');
		setPedido(null);
		setModalCancelarPedido(false);
	};

	const confirmarCancelarPedido = () => {
		let motivo = motivoCancelacion;
		let todoBien = true;
		if (motivo === 'otro') {
			if (otroMotivoCancelacion) {
				motivo = otroMotivoCancelacion;
			} else {
				enqueueSnackbar('Debe indicar el motivo de cancelación del pedido', {
					variant: 'error',
				});
				todoBien = false;
			}
		}
		if (!motivo) {
			enqueueSnackbar('Debe indicar el motivo de cancelación del pedido', {
				variant: 'error',
			});
			todoBien = false;
		}
		if (todoBien) {
			var options = {
				url: Api.route('a/pedidos/' + pedido.id + '/cancelar'),
				method: 'POST',
				data: {
					motivo: motivo,
				},
			};
			dispatch(AppActions.setLoading(true));
			dispatch(AppActions.setActionsDisabled(true));
			Api.requestAxios(
				options,
				(response) => {
					Helper.log('confirmarCancelarPedido response', response);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					if (response.data.error) {
						enqueueSnackbar(response.data.msg, { variant: 'error' });
					} else {
						enqueueSnackbar(response.data.msg, { variant: 'success' });
						setPaginador(paginadorInicial)
						buscarResultados(filtrosIniciales);
						cancelar();
					}
				},
				(error) => {
					Helper.log('error confirmarCancelarPedido', error);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					enqueueSnackbar('Error al cancelar pedido', { variant: 'error' });
				}
			);
		}
	};

	//CANCELAR
	const cancelar = () => {
		setPedido(null);
		setPanelVer(false);
		setPanelCrear(false);
		setPanelEditar(false);
		setModalEliminar(false);
	}

	const getPedido = (accion, id) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/pedidos/' + id),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getPedido response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data.datos) {
					switch (accion) {
						case 'ver':
							ver(response.data.datos);
							break;
						case 'editar':
							editar(response.data.datos);
							break;
						default:
							break;
					}
				}

			}
		}, error => {
			Helper.log('error getPedido', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
		});
	}

	const cambiarEstado = (id, estado) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/pedidos/' + id + '/estado'),
			method: 'POST',
			data: estado
		};
		Api.requestAxios(options, response => {
			Helper.log('cambiarEstado response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				buscarResultados(filtros);
			}
		}, error => {
			Helper.log('error cambiarEstado', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
		});
	}

	const handleAccion = (accion, pedido) => {
		switch (accion) {
			case 'ver':
				getPedido('ver', pedido.id);
				break;
			case 'editar':
				getPedido('editar', pedido.id);
				break;
			case 'cancelar':
				cancelarPedido(pedido);
				break;
			case 'eliminar':
				eliminar(pedido);
				break;
			case 'estado-procesando':
				cambiarEstado(pedido.id, { campo: 'procesando', valor: true });
				break;
			case 'estado-listo':
				cambiarEstado(pedido.id, { campo: 'listo', valor: true });
				break;
			case 'estado-enCamino':
				cambiarEstado(pedido.id, { campo: 'enCamino', valor: true });
				break;
			case 'estado-entregado':
				cambiarEstado(pedido.id, { campo: 'entregado', valor: true });
				break;
			case 'estado-pagado':
				cambiarEstado(pedido.id, { campo: 'pagado', valor: true });
				break;
			case 'estado-cancelado':
				cambiarEstado(pedido.id, { campo: 'cancelado', valor: true });
				break;
			case 'estado-finalizado':
				cambiarEstado(pedido.id, { campo: 'finalizado', valor: true });
				break;
			default:
				break;
		}
	}

	return (
		<div>
			<div className="w3-row">
				<div className="w3-col w3-right w3-right-align" style={{ width: '300px' }}>
					<Button onClick={() => crear()} color="primary" variant="contained">Agregar pedido</Button>
				</div>
				<div className="w3-rest">
					<h3>Pedidos</h3>
				</div>
			</div>
			<div className="w3-row">
				<div className="w3-col l6 w3-padding-small bs-bbox">
					{opcionesFiltroTipo.length > 0 ? (
						<FormControl
							variant="outlined"
							size="small"
							className={selectClass.formControl}
						>
							<InputLabel id="select-tipo-label">Tipo de pedido</InputLabel>
							<Select
								MenuProps={{
									style: { zIndex: 15000 },
								}}
								label="Tipo de pedido"
								labelId="select-tipo-label"
								id="select-tipo"
								value={tipoSelected}
								onChange={(e) => changeSelectTipo(e.target.value)}
							>
								<MenuItem value={'todos'}>
									Todos
								</MenuItem>
								{opcionesFiltroTipo.map((tipo, i) => (
									<MenuItem key={`filtro-tipo-${i}`} value={tipo.value}>
										{tipo.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : null}
				</div>
			</div>
			{pedidos.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
				<h3 className="w3-text-gray">No hay resultados</h3>
			</div>}
			{pedidos.length > 0 && (
				<>
					<ListaPedidos
						pedidos={pedidos}
						cambiarEstado={cambiarEstado}
						onAccion={handleAccion}
						conAcciones
						conPaginacion
					/>
					<div style={{ width: '100%', maxWidth: '500px', float: 'right' }}>
						<div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
						<div className="w3-cell">
							{opcionesPaginacionNumResultados.length > 0 ? <FormControl variant="outlined">
								<Select
									size="small"
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={paginador.cuantos}
									onChange={(e) => cambioCuantos(e)}
									input={<BootstrapInput />}>
									{opcionesPaginacionNumResultados.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
								</Select>
							</FormControl> : null}
						</div>
						<div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
						<div className="w3-cell w3-cell-middle">
							<IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
								<FontAwesomeIcon icon={faChevronLeft} size="sm" />
							</IconButton>
							<IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
								<FontAwesomeIcon icon={faChevronRight} size="sm" />
							</IconButton>
						</div>
					</div>
				</>
			)}

			{pedido && (
				<SlidingPanel type={'right'} isOpen={panelVer} size={100}>
					<PanelContainer close={cancelar} panelLabel="Ver pedido" closeLabel="Pedidos">
						<DetallesPedido onClose={cancelar} pedidoId={pedido.id} />
					</PanelContainer>
				</SlidingPanel>
			)}

			<SlidingPanel type={'right'} isOpen={panelCrear} size={100}>
				<PanelContainer close={cancelar} panelLabel="Nuevo pedido" closeLabel="Pedidos">
					<CrearPedido cancelar={cancelar} configPedidos={configPedidos} buscarResultados={buscarResultados} filtros={filtros} />
				</PanelContainer>
			</SlidingPanel>

			<SlidingPanel type={'right'} isOpen={panelEditar} size={100}>
				<PanelContainer close={cancelar} panelLabel="Edición pedido" closeLabel="Pedidos">
					<EditarPedido cancelar={cancelar} pedido={pedido} configPedidos={configPedidos} buscarResultados={buscarResultados} filtros={filtros} />
				</PanelContainer>
			</SlidingPanel>

			{(modalEliminar && pedido) ? <div className="w3-modal w3-show">
				<div className="w3-modal-content w3-animate-zoom w3-round-large">
					<div className="w3-row">
						<div className="w3-col w3-right" style={{ width: '50px' }}>
							<IconButton aria-label="delete" onClick={() => cancelar()}>
								&nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
							</IconButton>
						</div>
						<div className="w3-rest w3-padding w3-center w3-text-gray">
							<h2 style={{ margin: 0 }}><FontAwesomeIcon icon={faExclamationTriangle} /> Atención</h2>
						</div>
					</div>
					<div className="w3-container w3-padding">
						<p className="w3-large w3-margin w3-center">Estás a punto de eliminar el pedido con id: <b>{pedido.id}</b>. Ésta acción no se puede deshacer.</p>
						<p className="w3-large w3-margin w3-center">¿Quieres continuar?</p>
						<div className="w3-row w3-white">
							<div className="w3-col l6" style={{ padding: '30px 16px' }}>
								<button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
									type="button"
									onClick={() => cancelar()}>No</button>
							</div>
							<div className="w3-col l6" style={{ padding: '30px 16px' }}>
								<button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
									type="button"
									onClick={() => confirmarEliminar()}>Si</button>
							</div>
						</div>
					</div>
				</div>
			</div> : null}
			{modalCancelarPedido && pedido ? (
				<div className="w3-modal w3-show" style={{ zIndex: 1300 }}>
					<div className="w3-modal-content w3-animate-zoom w3-round-large" style={{ maxWidth: 600 }}>
						<div className="w3-row">
							<div className="w3-col w3-right" style={{ width: '50px' }}>
								<IconButton
									aria-label="delete"
									onClick={() => cancelarCancelarPedido()}
								>
									&nbsp;
									<FontAwesomeIcon icon={faTimes} />
									&nbsp;
								</IconButton>
							</div>
							<div className="w3-rest w3-padding w3-center w3-text-gray">
								<h2 style={{ margin: 0 }}>
									<FontAwesomeIcon icon={faExclamationTriangle} /> Motivo de
									cancelación del pedido
								</h2>
							</div>
						</div>
						<div className="w3-container w3-padding">
							<div className="w3-row">
								<div className="w3-col l6 w3-padding-small bs-bbox">
									<FormControl component="fieldset">
										<RadioGroup
											aria-label="motivoCancelacion"
											name="motivoCancelacion"
											value={motivoCancelacion}
											onChange={(e) => onChangeMotivo(e)}
										>
											{opcionesMotivosCancelacion.map((motivo, i) => (
												<FormControlLabel
													key={`motivo-${i}`}
													value={motivo.label}
													control={<RadioTheme />}
													label={motivo.label}
												/>
											))}
											<FormControlLabel
												value="otro"
												control={<RadioTheme />}
												label="Otro"
											/>
										</RadioGroup>
									</FormControl>
								</div>
								{motivoCancelacion === 'otro' ? (
									<div className="w3-col l12 w3-padding-small bs-bbox">
										<TextField
											fullWidth
											margin="normal"
											size="small"
											variant="outlined"
											id="input-motivo"
											label="Escribir motivo cancelación"
											type="text"
											name="motivo"
											value={otroMotivoCancelacion}
											onChange={onChangeOtroMotivo}
											disabled={state.appReducer.actionsDisabled}
										/>
									</div>
								) : null}
							</div>
							<div className="w3-row w3-white">
								<div
									className="w3-col l6 bs-bbox"
									style={{ padding: '30px 16px' }}
								>
									<button
										className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
										type="button"
										onClick={() => cancelarCancelarPedido()}
									>
										No cancelar
									</button>
								</div>
								<div
									className="w3-col l6 bs-bbox"
									style={{ padding: '30px 16px' }}
								>
									<button
										className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
										type="button"
										onClick={() => confirmarCancelarPedido()}
										disabled={!motivoCancelacion}
									>
										Confirmar cancelación
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
export default withRouter(Pedidos);