// init
export const init = () => ({
    type: 'INIT'
});
// setActionsDisabled
export const setActionsDisabled = (actionsDisabled) => ({
    type: 'ACTIONS_DISABLED',
    actionsDisabled: actionsDisabled
});
// setLoading
export const setLoading = (loading) => ({
    type: 'LOADING',
    loading: loading,
});
// setLastVisited
export const setLastVisited = (lastVisited) => ({
    type: 'LAST_VISITED',
    lastVisited: lastVisited,
});
// setMenuAbierto
export const setMenuAbierto = (menuAbierto) => ({
    type: 'MENU_ABIERTO',
    menuAbierto: menuAbierto,
});
// setItemsCarrito
export const setItemsCarrito = (itemsCarrito) => ({
    type: 'ITEMS_CARRITO',
    itemsCarrito: itemsCarrito,
});
// setPaginaActual
export const setPaginaActual = (paginaActual) => ({
    type: 'PAGINA_ACTUAL',
    paginaActual: paginaActual,
});
// El área de cobertura
export const setAreaCobertura = (areaCobertura) => ({
	type: 'AREA_COBERTURA',
	areaCobertura
})
// setSideMenuToggle
export const setSideMenuToggle = (sideMenuToggle) => ({
    type: 'SIDE_MENU_TOGGLE',
    sideMenuToggle: sideMenuToggle,
});
// setModificaciones
export const setModificaciones = (modificaciones) => ({
    type: 'MODIFICACIONES',
    modificaciones: modificaciones,
});
// setFiltrosProductos
export const setFiltrosProductos = (filtrosProductos) => ({
    type: 'FILTROS_PRODUCTOS',
    filtrosProductos: filtrosProductos,
});