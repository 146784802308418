import { DetallesPedido } from 'components/ListaPedidos';
import React from 'react';
import { withRouter, useParams } from "react-router-dom";
import SlidingPanel from 'react-sliding-side-panel';
import { PanelContainer } from 'components/sidenav';


const VerPedido = (props) => {
	const { pedidoId } = useParams();
	const { history } = props

	const handleClose = () => {
		if (history) history.push('/panel/pedidos');
	}

	return (
		<SlidingPanel type={'right'} isOpen={true} size={100}>
			<PanelContainer close={handleClose} panelLabel="Ver pedido" closeLabel="Pedidos">
				<DetallesPedido
					pedidoId={pedidoId}
					onClose={handleClose}
				/>
			</PanelContainer>
		</SlidingPanel>
	)
};
export default withRouter(VerPedido);