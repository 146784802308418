import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFileExcel } from '@fortawesome/free-solid-svg-icons';

import Chart from "react-google-charts";
// import { useWindowSize } from 'hooks';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { ListaPedidos } from 'components/ListaPedidos';

const Dashboard = props => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	// const state = useContext(StateContext);
	// const windowSize = useWindowSize();
	const [data, setData] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [ultimosPedidos, setUltimosPedidos] = useState([]);
	const [pathPlanillaVentasDelMes, setPathPlanillaVentasDelMes] = useState('a/reportes/pedidos/');
	const [pathPlanillaClientes, setPathPlanillaClientes] = useState('a/reportes/clientes');
	const [anhoMes, setAnhoMes] = useState(null);

	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Dashboard';
		getDashboard();
	}, []);
	const inicializarDatos = (datos) => {
		//chart
		let chDt = [["Fecha", "Num. Pedidos"]];
		if (datos.fechas) {
			for (var i in datos.fechas) {
				if (datos.fechas[i].numPedidos) {
					chDt.push([moment(i).format('D MMMM'), datos.fechas[i].numPedidos]);
				}
			}
		}
		setChartData(chDt);

		//ultimos pedidos
		let ultPeds = [];
		for (var i in datos.ultimosPedidos) {
			let ped = {
				...datos.ultimosPedidos[i]
			};
			ped.estados = {
				procesando: ped.procesando ? true : false,
				cancelado: ped.cancelado ? true : false,
				enCamino: ped.enCamino ? true : false,
				entregado: ped.entregado ? true : false,
				finalizado: ped.finalizado == '1' ? true : false,
				listo: ped.listo ? true : false,
				pagado: ped.pagado == '1' ? true : false,
			}
			ultPeds.push(ped);
		}
		setUltimosPedidos(ultPeds);

	}
	const getDashboard = () => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('a/dashboard'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getDashboard response', response);
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setData(response.data.datos);
				inicializarDatos(response.data.datos);
			}
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
		}, error => {
			Helper.log('error getDashboard', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer datos', { variant: 'error' });
		});
	}
	const onChangeAnhoMes = (date) => {
		setAnhoMes(date);
	};
	return (
		<div>
			{data ? <div>
				<div className="w3-row">
					<div className="w3-col l3 m6 s12 w3-padding bs-bbox">
						<div className="w3-card w3-padding w3-white w3-round-large">
							<h3>Pedidos</h3>
							<h1 className="w3-text-theme">{data.numPedidos}</h1>
						</div>
					</div>
					<div className="w3-col l3 m6 s12 w3-padding bs-bbox">
						<div className="w3-card w3-padding w3-white w3-round-large">
							<h3>Ventas</h3>
							<h1 className="w3-text-theme">{Helper.numberUi(data.montoTotal)} Gs.</h1>
						</div>
					</div>
					<div className="w3-col l3 m6 s12 w3-padding bs-bbox">
						<div className="w3-card w3-padding w3-white w3-round-large">
							<h3>Planilla clientes</h3>
							{pathPlanillaClientes ? <a target="_blank" download className="w3-btn w3-block w3-green w3-round" href={CONFIG.URL_BASE + pathPlanillaClientes}>
								<FontAwesomeIcon icon={faFileExcel} fontSize={18} color={'#fff'} />
								<span>&nbsp;Descargar</span>
							</a> : null}
						</div>
					</div>
					<div className="w3-col l3 m6 s12 w3-padding bs-bbox">
						<div className="w3-card w3-padding w3-white w3-round-large">
							<h3 style={{ marginBottom: '0px' }}>Planilla ventas</h3>
							<KeyboardDatePicker
								cancelLabel="Cancelar"
								clearLabel="Limpiar"
								okLabel="Ok"
								clearable
								id="anho-mes"
								label="Año/Mes"
								format="YYYY-MM"
								value={anhoMes}
								onChange={onChangeAnhoMes}
								views={['year', 'month']}
								KeyboardButtonProps={{
									'aria-label': 'Cambiar año y mes',
								}}
								style={{ marginBottom: 5 }}
							/>
							{anhoMes ? <a target="_blank" download className="w3-btn w3-block w3-green w3-round" style={{ marginTop: '3px' }} href={CONFIG.URL_BASE + pathPlanillaVentasDelMes + moment(anhoMes).format('YYYY') + '/' + moment(anhoMes).format('MM')}>
								<FontAwesomeIcon icon={faFileExcel} size="md" color={'#fff'} />
								<span>&nbsp;Descargar</span>
							</a> : null}
						</div>
					</div>
				</div>
				{chartData ? <div className="w3-row">
					<div className="w3-col l12 w3-padding bs-bbox">
						<div className="w3-card w3-padding w3-white w3-round-large">
							<h3>Últimos pedidos</h3>
							{chartData.length > 1 ? (
								<Chart
									chartType="ColumnChart"
									width="100%"
									height="400px"
									data={chartData}
								/>
							) : (
								<h3 className="w3-text-gray">No hay pedidos registrados</h3>
							)}
						</div>
					</div>
				</div> : null}
				<div className="w3-row">
					<div className="w3-col l12 w3-padding bs-bbox">
						<div className="w3-card w3-white w3-round-large" style={{ padding: '16px 16px 60px 16px' }}>
							<h3>Últimos pedidos</h3>
							{ultimosPedidos.length > 0 ? (
								<div className="w3-block w3-margin-top">
									<ListaPedidos
										pedidos={ultimosPedidos}
									/>
								</div>
							) : (
								<h3 className="w3-text-gray">No hay pedidos registrados</h3>
							)}
						</div>
					</div>
				</div>
			</div> : null}
		</div>
	);
}
export default withRouter(Dashboard);