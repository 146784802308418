export const ENTREGA_TIPO_RETIRO_TIENDA = 'tienda' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ENVIO = 'envio' // confirmar pedido sin pagar
export const ENTREGA_TIPO_ONLINE_RETIRO_TIENDA = 'online-tienda' // confirmar pedido al pagar
export const ENTREGA_TIPO_ONLINE_ENVIO = 'online-envio' // confirmar pedido al pagar

// Modificar esto de acuerdo a las necesidades del proyecto
export const OPCIONES_TIPOS_PEDIDO_PARA_FILTRO = [
	{
		label: 'Envío y pago online',
		value: ENTREGA_TIPO_ONLINE_ENVIO
	},
	{
		label: 'Envío y pago contra entrega',
		value: ENTREGA_TIPO_ENVIO
	},
	{
		label: 'Retiro en tienda y pago contra entrega',
		value: ENTREGA_TIPO_RETIRO_TIENDA
	},
	{
		label: 'Retiro en tienda y pago online',
		value: ENTREGA_TIPO_ONLINE_RETIRO_TIENDA
	},
]

export const OPCIONES_TIPOS_PEDIDO_PARA_CREACION = [
	{
		label: 'Envío y pago contra entrega',
		value: ENTREGA_TIPO_ENVIO
	},
	{
		label: 'Retiro en tienda y pago contra entrega',
		value: ENTREGA_TIPO_RETIRO_TIENDA
	},
]

export const OPCIONES_ESTADO_PEDIDO_PENDIENTE = [
	{
		key: 'procesando',
		label: 'Procesando'
	},
	{
		key: 'listo',
		label: 'Listo'
	},
	{
		key: 'enCamino',
		label: 'En camino'
	},
	{
		key: 'entregado',
		label: 'Entregado'
	},
]

export const OPCIONES_ESTADO_PEDIDO_HISTORIAL = [
	{
		key: 'entregado',
		label: 'Entregado'
	},
]

export const getEstadoReglaDisabled = (estado, pedido) => {
	let disabled = false
	switch (estado) {
		case 'procesando':
			disabled = pedido.estados.listo
			break
		case 'listo':
			disabled = !pedido.estados.procesando || pedido.estados.enCamino || pedido.estados.entregado
			break
		case 'enCamino':
			disabled = !pedido.estados.listo || typeof pedido?.puntoEntrega === 'undefined' || pedido.estados.entregado
			break
		case 'entregado':
			disabled = (typeof pedido?.puntoEntrega !== 'undefined' && !pedido.estados.enCamino) || !pedido.estados.listo
			break
	}

	return disabled
}

export const isConEnvio = (tipo) => {
	let conEnvio = false
	if (tipo === 'envio' || tipo === 'online-envio') {
		conEnvio = true
	}

	return conEnvio
}

// Lo que envía al server como motivo de cancelación es lo mismo que se le muestra al usuario
export const OPCIONES_MOTIVOS_CANCELACION = [
	{
		label: 'Stock insuficiente'
	}
]

// Los elementos del array se utilizan tanto como value como para label
export const OPCIONES_PAGINACION_NUM_RESULTADOS = [
	10, 20, 30, 50, 100
]