const production = true;
const ID = 'tiendoline-admin';
const APP_NAME = 'Tiendoline admin';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LfgINAoAAAAACNjBdRzX4q5w1aDG-XFPIiV2nrJ';
const DEFAULT_LANG = 'es';
const LANGS = [
    {id: 'es', label: 'Español', enabled: true},
    {id: 'en', label: 'Inglés', enabled: false},
    {id: 'de', label: 'Alemán', enabled: false},
    {id: 'pt', label: 'Portugués', enabled: false}
];
const DEVELOPMENT = {
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'http://192.168.100.39/tiendoline/admin',//localhost
    BASENAME: '/tiendoline/admin',//subdirectorio del host
    URL_BASE: 'http://192.168.100.39/tiendoline/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    DEFAULT_LANG: DEFAULT_LANG,
    LANGS: LANGS,
    LOGS: true
}
const PRODUCTION = {
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://admin.floreriafranco.com.py',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api.floreriafranco.com.py/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    DEFAULT_LANG: DEFAULT_LANG,
    LANGS: LANGS,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;