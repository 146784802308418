import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import './clientes.css';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
const VerCliente = (props) => {
	const [cliente, setCliente] = useState(props.cliente);

	return (
		<div>
			<div className="w3-padding-top bs-bbox">
				<form className="w3-margin-bottom w3-padding">
					<div className="w3-row">
						<div className="w3-col l4 w3-padding">
							<p>Estás viendo los datos de un cliente</p>
						</div>
						<div className="w3-col l8 w3-padding bs-bbox">
							<div className="w3-white bs-bbox w3-padding-large">
								<TextField fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="Usuario"
									value={cliente.usuario}
									disabled={true} /><br /><br />

								<TextField required fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="Nombre"
									value={cliente.nombre}
									disabled={true} /><br /><br />

								<TextField required fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="Apellido"
									value={cliente.apellido}
									disabled={true} /><br /><br />

								<TextField required fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="Teléfono"
									value={cliente.telefono}
									disabled={true} /><br /><br />

								{/*
								<TextField required fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="Email"
									value={cliente.email}
									disabled={true} /><br /><br />
								*/}

								<TextField required fullWidth margin="normal" variant="outlined" size="small"
									type="text"
									label="RUC"
									value={cliente.ruc}
									disabled={true} /><br /><br />
							</div>
						</div>
					</div>
				</form>
				<Divider />
				<div className="w3-row w3-white">
					<div className="w3-col l6" style={{ padding: '30px 16px' }}>
						<button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
							type="button"
							onClick={() => props.cancelar()}>Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default withRouter(VerCliente);