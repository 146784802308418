import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './clientes.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const CrearCliente = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);

	const { onCancelar, onSuccess } = props

	const [cliente, setCliente] = useState({
		abm: 'nuevo',
		usuario: '',
		nombre: '',
		apellido: '',
		email: '',
		telefono: '',
		ruc: '',
		contrasena: '',
		reContrasena: ''
	});
	const [noCoincidenPass, setNoCoincidenPass] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [rePasswordVisible, setRePasswordVisible] = useState(false);

	const validar = () => {
		let errores = {
			usuario: '',
			nombre: '',
			apellido: '',
			email: '',
			telefono: '',
			ruc: '',
			contrasena: '',
			reContrasena: ''
		}

		const camposObligatorios = ['usuario', 'nombre', 'apellido']
		camposObligatorios.forEach(campo => {
			if (cliente[campo] === '') {
				errores[campo] = 'El campo es requerido'
			}
		})

		if (cliente.usuario !== '' && !Helper.isValidEmail(cliente.usuario)) {
			errores.usuario = 'El campo debe ser una cuenta de correo electrónico'
		}

		if (cliente.contrasena === '') {
			errores.contrasena = 'Favor elegir una contraseña'
		}

		if (cliente.reContrasena === '') {
			errores.reContrasena = 'Favor confirmar la contraseña'
		}

		if (cliente.contrasena && cliente.reContrasena && cliente.contrasena !== cliente.reContrasena) {
			errores.reContrasena = 'Las contraseñas no coinciden'
		}

		return errores
	}

	const errores = validar()

	const handleCancelar = () => {
		onCancelar()
	}

	const handleSuccess = () => {
		onSuccess()
	}

	useEffect(() => {
		const { contrasena, reContrasena } = cliente;
		if (reContrasena && contrasena) {
			if (reContrasena === contrasena) {
				setNoCoincidenPass(false);
			} else {
				setNoCoincidenPass(true);
			}
		}
	}, [cliente]);
	const handleChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		setCliente({ ...cliente, [name]: value });
	};
	const guardar = () => {
		// verifica que no haya errores
		let todoBien = true
		for (const key in errores) {
			if (errores[key]) {
				todoBien = false
			}
		}

		// extrae los datos
		const data = {
			email: cliente.usuario
		}

		if (todoBien) {
			const campos = ['usuario', 'nombre', 'apellido', 'telefono', 'ruc', 'contrasena']
			campos.forEach(campo => {
				data[campo] = cliente[campo]
			})

			dispatch(AppActions.setLoading(true));
			dispatch(AppActions.setActionsDisabled(true));
			var options = {
				url: Api.route('a/clientes'),
				data: data,
				method: 'POST'
			};

			Api.requestAxios(options)
				.then(response => {
					// Esta función se ejecuta cuando la petición tiene éxito
					Helper.log('nuevoCliente response', response);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					if (response.data.error) {
						enqueueSnackbar(response.data.msg, { variant: 'error' });
					} else {
						enqueueSnackbar(response.data.msg, { variant: 'success' });
						handleSuccess();
					}
				})
				.catch(error => {
					// Esta función se ejecuta cuando la petición falla
					Helper.log('error nuevoCliente', error);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					enqueueSnackbar('Error al subir nuevo cliente', { variant: 'error' });
				});
		}
	};
	return (
		<div>
			<div className="w3-padding-top bs-bbox">
				<form className="w3-margin-bottom w3-padding">
					<div className="w3-row">
						<div className="w3-col l4 w3-padding">
							<p>Completá los datos y luego presioná el botón "Guardar"</p>
						</div>
						<div className="w3-col l8 w3-padding bs-bbox">
							<div className="w3-white bs-bbox w3-padding-large">
								<TextField
									required
									fullWidth size="small"
									margin="normal"
									variant="outlined"
									error={errores.usuario !== ''}
									name="usuario"
									type="email"
									label="Usuario"
									value={cliente.usuario}
									placeholder='nombredelusuario@ejemplo.com'
									helperText={errores.usuario}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br />

								<TextField required fullWidth size="small" margin="normal" variant="outlined"
									error={errores.nombre !== ''}
									name="nombre"
									type="text"
									label="Nombre"
									value={cliente.nombre}
									helperText={errores.nombre}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br />

								<TextField required fullWidth size="small" margin="normal" variant="outlined"
									error={errores.apellido !== ''}
									name="apellido"
									type="text"
									label="Apellido"
									value={cliente.apellido}
									helperText={errores.apellido}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br />
								<TextField fullWidth size="small" margin="normal" variant="outlined"
									error={errores.telefono !== ''}
									name="telefono"
									type="text"
									label="Teléfono"
									value={cliente.telefono}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br /><br />

								{/*
                                <TextField required fullWidth size="small" margin="normal" variant="outlined"
                                    error={errores.email}
                                    name="email"
                                    type="email"
                                    label="Email"
                                    value={cliente.email}
                                    onChange={handleChange}
                                    disabled={state.appReducer.actionsDisabled} /><br /><br /><br />
								*/}

								<TextField fullWidth size="small" margin="normal" variant="outlined"
									error={errores.ruc !== ''}
									name="ruc"
									type="text"
									label="RUC"
									value={cliente.ruc}
									onChange={handleChange}
									disabled={state.appReducer.actionsDisabled} /><br /><br /><br />


								<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
									<InputLabel htmlFor="input-password">Contraseña*</InputLabel>
									<OutlinedInput
										error={errores.contrasena !== ''}
										id="input-password"
										type={passwordVisible ? 'text' : 'password'}
										value={cliente.contrasena}
										onChange={handleChange}
										name="contrasena"
										disabled={(state.appReducer.actionsDisabled) ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setPasswordVisible((visible) => !visible)}
													onMouseDown={e => e.preventDefault()}
													edge="end"
												>
													{passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
												</IconButton>
											</InputAdornment>
										}
										labelWidth={85}
									/>
									<FormHelperText
										error={errores.contrasena !== ''}>
										{errores.contrasena}
									</FormHelperText>
								</FormControl><br /><br /><br />

								<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
									<InputLabel htmlFor="input-repassword">Repetir contraseña*</InputLabel>
									<OutlinedInput
										error={errores.reContrasena !== ''}
										id="input-repassword"
										type={rePasswordVisible ? 'text' : 'password'}
										value={cliente.reContrasena}
										onChange={handleChange}
										name="reContrasena"
										disabled={(state.appReducer.actionsDisabled) ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setRePasswordVisible((visible) => !visible)}
													onMouseDown={e => e.preventDefault()}
													edge="end"
												>
													{rePasswordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
												</IconButton>
											</InputAdornment>
										}
										labelWidth={145}
									/>
									<FormHelperText
										error={errores.reContrasena !== ''}>
										{errores.reContrasena}
									</FormHelperText>
								</FormControl><br /><br /><br />
							</div>
						</div>
					</div>
				</form>
				<Divider />
				<div className="w3-row w3-white">
					<div className="w3-col l6" style={{ padding: '30px 16px' }}>
						<button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
							type="button"
							onClick={handleCancelar}>Cancelar</button>
					</div>
					<div className="w3-col l6" style={{ padding: '30px 16px' }}>
						<button className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
							type="button"
							onClick={guardar}>Guardar</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default withRouter(CrearCliente);